import React, { Component } from "react";

import Header from "./components/Header";
import TYNavigation from "./components/TYNavigation";

export default class ThankYou extends Component {
  render() {
    return (
      <div id="page-top">
        <TYNavigation navTo="/" />
        <Header />
      </div>
    );
  }
}
